@tailwind base;

@layer base {
  @font-face {
    font-family: gt-super;
    src: url("./assets/fonts/gt-super-400-normal.woff");
  }

  @font-face {
    font-family: sohne;
    src: url("./assets/fonts/sohne-400-normal.woff");
  }

  @font-face {
    font-family: sohne-italic;
    src: url("./assets/fonts/sohne-400-italic.woff");
  }

  @font-face {
    font-family: sohne-bold;
    src: url("./assets/fonts/sohne-700-normal.woff");
  }

  @font-face {
    font-family: sohne-semibold;
    src: url("./assets/fonts/sohne-500-normal.woff");
  }

  @font-face {
    font-family: fell;
    src: url("./assets/fonts/fell-400-normal.woff");
  }

  @font-face {
    font-family: content-serif;
    src: url("./assets/fonts/content-serif.woff");
  }

  @font-face {
    font-family: content-sans;
    src: url("./assets/fonts/content-sans.woff");
  }

  @font-face {
    font-family: content-sans-bold;
    src: url("./assets/fonts/content-sans-bold.woff");
  }

  @font-face {
    font-family: source-serif-pro;
    src: url("./assets/fonts/source-serif-pro-400-normal.woff");
  }

  @font-face {
    font-family: source-serif-pro-bold;
    src: url("./assets/fonts/source-serif-pro-700-normal.woff");
  }

  /* Apply color to google icon */
  .fa-google {
    background: conic-gradient(
        from -45deg,
        #ea4335 110deg,
        #4285f4 90deg 180deg,
        #34a853 180deg 270deg,
        #fbbc05 270deg
      )
      73% 55%/150% 150% no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
  }
}
.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
  background: #09A9FF;
  background: linear-gradient(to right, #09A9FF 39%, #0CFFFB 50%, #17BDFF 71%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family:arial
}
.justify-center {
  justify-content: center;
  background-color: #fbfbfb!important;
}
.bg-black {
  --tw-bg-opacity: 1;
  background-color: #30a9fc!important;
}
.bg-lightblack {
  --tw-bg-opacity: 1;
  background-color: #30a9fc !important;
  border: none !important;
}

.text-sm {
  font-size: 0.665rem!important;
  line-height: 1.5rem;
}
.srch{
  font-size:12px;
}

.logo-h{
  left:-20px;position:relative
}
.signup-row {
  display: flex;
  width: 210px!important;
  right: -10px;
    position: relative;
}

.postImg{
  border-radius: 7px;margin-bottom:10px;
}
.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: #239bf5!important;
}

.border-blue-500 {
  --tw-border-opacity: 1;
  border-color: #239bf5!important;
}

@tailwind components;
@tailwind utilities;
